.gallery {
    display: flex;
    flex-direction: column;
}

.gallery .row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-bottom: 2vw;
}

.gallery .row img {
    width: 45vw;
    height: auto;
    border: 2px solid black;
    border-radius: 5px;
}

@media only screen and (max-width: 957px) {
    .gallery .row {
        flex-direction: column;
        align-items: center;
    }

    .gallery .row img {
    width: 80vw;
    height: auto;
    }
}