.container {
    display: flex;
    flex-direction: row;
    padding-left: 10vw;
    padding-right: 10vw;
}

address a {
    text-decoration: none;
    color: black;
}

address a:hover {
    color: #f5bc42;
}

.formContainer {
    display: flex;
    flex-direction: column;
    margin-top: 1vw;
    width: 100%;
}

.form {
    display: flex;
    flex-direction: column;
    margin-left: 30%;
    margin-right: 30%;
    border: 1px groove #d4d2cd;
    border-radius: 5px;
    box-shadow: 10px 10px 5px grey;
}

.form .formContent {
    margin: 1vw;
}

.form .formField {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 10px;
}

.form .formField .label {
    display: flex;
    justify-content: flex-start;
}

.form .formField .singleLineInput {
    display: flex;
    justify-content: flex-start;
    outline: none;
    border: none;
    border-bottom: 1px solid black;
}

.form .formField .singleLineInput:focus {
    border-bottom: 1px solid #f5bc42;
}

.form .formField .multiLineInput {
    resize: none;
    display: flex;
    justify-content: flex-start;
    outline: none;
    border: 1px solid black;
}

.form .formField .multiLineInput:focus {
    border: 1px solid #f5bc42;
}

.form .submit {
    margin-top: 1vh;
    background-color: black;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    border-radius: 10px;
    outline: none;
}

.form .submit:hover {
    background-color: #f5bc42;
}

.validationMessage {
    color: red;
}

@media only screen and (max-width: 957px) {
    .container {
        flex-direction: column;
    }

    .form {
        margin-left: 0;
        margin-bottom: 3vw;
        width: 100%;
    }

    .formContainer input {
        width: 100%;
    }
  }