.container {
    display: flex;
    justify-content: center;
}

@media only screen and (max-width: 957px) {
    .element {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
    }
}

@media only screen and (min-width: 957px) {
    .circle {
        border: 60px solid #fdc312;
        width: 200px;
        height: 200px;
        border-radius: 50%;
        position: absolute;
        margin-top: 50px;
    }

    .circle::after {
        height: 50px;
        width: 50px;
        background-color: black;
        border-radius: 50%;
        margin: 38%;
        position: absolute;
        content: "";
    }

    .circle .angle {
        width: 100px;
        height: 100px;
        position: absolute;
        border-radius: 50%;
        left: 50%;
        top: 50%;
    }

    .circle .angle:nth-child(1) .element {
        transform: rotate(0deg);
    }
    .circle .angle:nth-child(2) .element {
        transform: rotate(-60deg);
    }
    .circle .angle:nth-child(3) .element {
        transform: rotate(-120deg);
    }
    .circle .angle:nth-child(4) .element {
        transform: rotate(-180deg);
    }
    .circle .angle:nth-child(5) .element {
        transform: rotate(-240deg);
    }
    .circle .angle:nth-child(6) .element {
        transform: rotate(-300deg);
    }

    .circle .angle:nth-child(1) {
        transform: rotate(0deg) translate(220px);
    }
    .circle .angle:nth-child(2) {
        transform: rotate(60deg) translate(240px);
    }
    .circle .angle:nth-child(3) {
        transform: rotate(120deg) translate(300px);
    }
    .circle .angle:nth-child(4) {
        transform: rotate(180deg) translate(300px);
    }
    .circle .angle:nth-child(5) {
        transform: rotate(240deg) translate(300px);
    }
    .circle .angle:nth-child(6) {
        transform: rotate(300deg) translate(300px);
    }
}

.icon {
    width: 100px;
    height: 100px;
}