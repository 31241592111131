.navBarContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.navBarContainer .logo {
    height: auto;
    max-width: 50%;
}

.navBarContainer .navBarList {
    display: flex;
    align-items: center;
    list-style: none;
    background-color: black;
    border-radius: 10px;
}

.navBarList .navBarButton {
    margin: 10%;
    min-width: 50px;
    font-size: 3vh;
    margin: 0 2vw;
}

.navBarList .navBarButton a {
    text-decoration: none;
    color: white;
}

.navBarList .navBarButton a:hover {
    color: #f5bc42;
}

@media only screen and (max-width: 957px) {
    .navBarContainer {
        flex-direction: column;
    }
    .navBarList{
        flex-direction: column;
    }
  }